declare global {
  interface Window {
    smartlook: any;
  }
}

const initSmartlook = (apiKey: string, users: string, loginName: string, asInfo?: string) => {
  if (!loginName || !apiKey) return;

  if (users != null) {
    const usersArray = users.split(";");
    const foundUser = usersArray.some((user) => user.toLowerCase().trim() === loginName.toLowerCase().trim());

    if (!foundUser) return;
  }

  window.smartlook ||
    (function (d) {
      var o: any = (window.smartlook = function () {
          o.api.push(arguments);
        }),
        h = d.getElementsByTagName("head")[0];
      const c = d.createElement("script");
      o.api = [];
      c.async = true;
      c.type = "text/javascript";
      c.charset = "utf-8";
      c.src = "https://web-sdk.smartlook.com/recorder.js";
      h.appendChild(c);
    })(document);
  window.smartlook("init", apiKey, { region: "eu" });
  window.smartlook("record", { forms: true, ips: true, api: true });
  window.smartlook("identify", `${loginName}-${asInfo ? asInfo : "unknown"}`);
};

export default initSmartlook;

export function smartlookTrack(eventName: string, eventDetail?: object) {
  if (window.smartlook) {
    window.smartlook("track", eventName, eventDetail);
  }
}
