import React, { useEffect, useMemo, useRef } from "react";
import { isPDF } from "../../common/common";
import { cJSonFunctionSendInterchangeData, UpdateImage, UpdateWebView } from "../../common/communication.base";
import { NclWebView } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { WithContextPlacementProps, StyleHelper } from "../k2hoc";
import "./WebView.scss";
import { K2BusyIndicator } from "../BusyIndicator/K2BusyIndicator";

export default function K2WebView(props: WithContextPlacementProps) {
  const [control, data, element] = useServerState<NclWebView, UpdateWebView, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclWebView
  );
  const iframe = useRef<HTMLIFrameElement>(null);
  const busyWrap = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (iframe.current) {
      window.addEventListener("message", handleMessage);
      window.addEventListener("blur", activeElementChanged, true);
    }
    return () => {
      window.removeEventListener("message", handleMessage);
      window.removeEventListener("blur", activeElementChanged, true);
    };
  }, []);

  useEffect(() => {
    updateInterchangeData();
  }, [data.InterchangeFieldData]);

  function handleIframeLoad() {
    if (busyWrap.current) busyWrap.current.style.display = "none";
    updateInterchangeData();
  }

  function handleMessage(event: MessageEvent) {
    if (event?.data?.function === "SendInterchangeData") {
      control.appendFunction({ Name: cJSonFunctionSendInterchangeData, Args: event?.data?.payload || [] }, true);
    }
  }

  function handleFocus() {
    control.setActiveControlRequested();
    control.setAsActiveControl();
    control.updateFocus(true);
  }

  function activeElementChanged() {
    if (document.activeElement === iframe.current) {
      handleFocus();
    }
  }

  function updateInterchangeData() {
    if (iframe.current && iframe.current.contentWindow && data.InterchangeFieldData) {
      iframe.current.contentWindow.postMessage({ function: "ReceiveInterchangeData", payload: [data.InterchangeFieldData] }, data.ObjectId);
    }
  }

  if (!data.ObjectId) return;

  if (isPDF(data.ObjectId)) {
    return (
      <div className="wv" ref={element} style={StyleHelper(control, props.style)} key={data.ObjectId}>
        <object data={data.ObjectId} className="wv_object" onFocus={handleFocus} />
      </div>
    );
  } else {
    return (
      <div className="wv" ref={element} style={StyleHelper(control, props.style)} key={data.ObjectId}>
        <div ref={busyWrap}>
          <K2BusyIndicator visibility={true}></K2BusyIndicator>
        </div>
        <iframe
          ref={iframe}
          onLoad={handleIframeLoad}
          src={data.ObjectId}
          className="wv_object wv_iframe"
          sandbox="allow-scripts allow-popups allow-modals allow-forms allow-same-origin allow-presentation"
          allowFullScreen
        />
      </div>
    );
  }
}
