import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { NclPage, NclVRTabControl } from "../../common/components.ncl";
import K2Scroll from "../Scroll/K2Scroll";
import { K2RibbonAction } from "../RibbonAction/K2RibbonAction";
import K2Img from "../Image/K2Img";
import K2MainMenu from "./K2MainMenu";
import K2Tab from "./K2Tab";
import K2TabContent from "./K2TabContent";
import { TabControlPropsBase } from "./K2TabControl";
import css from "./VRTabControl.scss";
import { MainMenuData, UpdateVRTabControl } from "../../common/communication.base";

interface VRTabControlProps extends TabControlPropsBase<NclVRTabControl> {
  mainMenuDataVersion?: number;
}

const K2VRTabControl = (props: VRTabControlProps) => {
  const [showNewMenu, setShowNewMenu] = useState(false);
  const lastDynamicPage = useRef("");

  props.control.visiblePages().map((page) => {
    if (page.Ncl.IsDynamic && page.Ncl.PageUID === props.currentPage) {
      lastDynamicPage.current = page.Ncl.PageUID;
    }
  });

  const getClassName = (page: NclPage) => {
    let className = css.tc_vr_button;

    if (!page.Ncl.IsDynamic || page.Ncl.PageUID === lastDynamicPage.current) {
      className += ` ${css.tc_always_visible}`;
    }

    if (props.currentPage === page.Ncl.PageUID) {
      className += ` ${css.tc_current_page}`;
    }

    if (page.Ncl.IsDynamic) {
      className += ` ${css.tc_dynamic}`;
    }

    return className;
  };

  useEffect(() => {
    if (props.mainMenuDataVersion > 0) setShowNewMenu(true);
  }, [props.mainMenuDataVersion]);

  function showMainMenu() {
    if (props.control.MainMenuData) {
      setShowNewMenu(true);
    } else {
      props.control.loadMainMenuData();
    }
  }

  return (
    <div className={css.tc_vr}>
      <div className={css.tc_vr_tabs} style={{ backgroundColor: props.control.CompanyColor ? props.control.CompanyColor : undefined }}>
        <div className={`${css.tc_vr_menu} button`} onClick={showMainMenu}>
          <K2Img glyphId="wui*list" height={props.control.VCX.sizeMap(20)} width={props.control.VCX.sizeMap(20)} vcx={props.control.VCX} />
        </div>
        <K2Scroll orientation={props.control.getOrientation()} control={props.control}>
          {props.control.visiblePages().map((page) => {
            return (
              <K2Tab
                key={page.MetaData.ControlUID + "." + page.key}
                controlUID={page.MetaData.ControlUID}
                vrUID={props.control.getRealizerUID()}
                className={getClassName(page)}
                onClick={props.setShowMobileMenu}
                lastDynamicPage={lastDynamicPage.current}
              />
            );
          })}
        </K2Scroll>
        <div className="tc_actions">
          {props.control.Btns?.map((item, index) => {
            return (
              <K2RibbonAction
                heightIcon={props.control.VCX.sizeMap(20)}
                key={`tab_btn_${index}`}
                style={{ height: "auto" }}
                controlUID={item.MetaData.ControlUID}
                vrUID={props.control.getRealizerUID()}
                color={props.control.VCX.getColor(props.control.VCX.Data.ColorMap.BaseColorFrg1)}
              />
            );
          })}
        </div>
      </div>
      <K2TabContent control={props.control} currentPage={props.currentPage} />
      {showNewMenu && <K2MainMenu data={props.control.MainMenuData?.DataFromDM} setShowNewMenu={setShowNewMenu} />}
    </div>
  );
};

export default K2VRTabControl;
