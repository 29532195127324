import * as React from "react";
import { K2ComponentState, AcquireControl, StyleHelper } from "./../k2hoc";
import { UpdateControl, UpdateRibbon, UpdateInnerToolbar } from "../../common/communication.base";
import { NclRibbon, NclInnerToolBar, NclInnerSection, UFNclControlBase, NclCommandItem, NclBreaker, NclAccessor } from "../../common/components.ncl";
import { GenerateControl } from "./../K2GenerateControl";
import { K2RibbonAction, K2ControlBreaker } from "./../RibbonAction/K2RibbonAction";
import { ViewRealizer, ViewRealizerManager } from "../../viewrealizer";
import { Context } from "../../appcontext";
import css from "./Ribbon.scss";
import { withVCXInCSS, WithVCXinCSSProps } from "../VCX/VCXHelper";
import resolveContextMenu from "../../utils/resolveContextMenu";

interface InnerSectionProps extends WithVCXinCSSProps {
  size: number;
}

interface InnerSectionState extends K2ComponentState<UpdateControl> {
  visible: boolean;
}

class _InnerSection extends React.PureComponent<InnerSectionProps, InnerSectionState> {
  private control: NclInnerSection;
  private element: HTMLElement;

  constructor(props: InnerSectionProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInnerSection;
    }) as NclInnerSection;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1, visible: false };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: InnerSectionState) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidUpdate(prevProps: Readonly<InnerSectionProps>, prevState: Readonly<InnerSectionState>, snapshot?: any): void {
    if (prevState.vcxVersion !== this.state.vcxVersion) {
      if (this.props.onVCXChanged && this.element) {
        this.props.onVCXChanged(this.control.VCX, this.element);
      }
    }
  }

  componentDidMount(): void {
    if (this.element) {
      if (this.props.onVCXChanged && this.control.VCX != this.control.Parent?.VCX) {
        this.props.onVCXChanged(this.control.VCX, this.element);
      }

      this.element.style.setProperty("--ribbon-size", this.control.Parent?.Parent?.Size?.toString());
    }
  }

  updateVisible = () => {
    this.setState({ visible: true });
  };

  private renderItem(item: UFNclControlBase): JSX.Element {
    let content: JSX.Element = null;

    if (item instanceof NclCommandItem) {
      content = (
        <K2RibbonAction
          key={"item_" + item.MetaData.ControlUID}
          captionLinesCount={this.control.Ribbon.Ncl.FrgtData.TileCaptionLineCount}
          vrUID={item.getRealizerUID()}
          controlUID={item.MetaData.ControlUID}
          color={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentDecorateColorFrg)}
          updateVisible={this.updateVisible}
          size={this.props.size}
        />
      );
    } else if (item instanceof NclBreaker) {
      content = <K2ControlBreaker key={"item_" + item.MetaData.ControlUID} vrUID={item.getRealizerUID()} controlUID={item.MetaData.ControlUID} />;
    } else {
      content = GenerateControl(item);
    }
    return content;
  }

  render() {
    // osetruje pripad, kdy je ribbon skryty a uzivatel klikne na ousko (accessor) - v tu dobu neprobiha komunikace se serverem
    // nevola se tedy metoda updateVisible, ale staci jen overit, jestli je nejaky button visible
    const anyButtonIsVisible = this.control.ContentItems.some((button) => button instanceof NclCommandItem && button.State.Visible === true);

    return (
      <div
        ref={(ref) => (this.element = ref)}
        style={StyleHelper(this.control, { ...this.props.style, display: anyButtonIsVisible ? "flex" : this.state.visible ? "flex" : "none" })}
        className={css.rb_isection_base}
      >
        <div className={css.rb_isection_subsection_base}>{this.control.ContentItems.map((item) => this.renderItem(item))}</div>
        <div className={css.rb_isection_name + " " + css.rb_isection_font}>{this.control.Ncl.Caption}</div>
      </div>
    );
  }
}

const K2InnerSection = withVCXInCSS(_InnerSection);

interface InnerToolBarProps extends WithVCXinCSSProps {
  showMenu: boolean;
  size: number;
}

class _InnerToolBar extends React.PureComponent<InnerToolBarProps, K2ComponentState<UpdateInnerToolbar>> {
  private control: NclInnerToolBar;
  private element: HTMLElement;

  constructor(props: InnerToolBarProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInnerToolBar;
    }) as NclInnerToolBar;
    this.state = { data: this.control.init(this) as UpdateInnerToolbar, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateInnerToolbar>) => {
      return { data: state as UpdateInnerToolbar };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidUpdate(prevProps: Readonly<InnerToolBarProps>, prevState: Readonly<K2ComponentState<UpdateInnerToolbar>>, snapshot?: any): void {
    if (prevState.vcxVersion !== this.state.vcxVersion) {
      if (this.props.onVCXChanged && this.element) {
        this.props.onVCXChanged(this.control.VCX, this.element);
      }
    }
  }

  componentDidMount(): void {
    if (this.element && this.props.onVCXChanged && this.control.VCX != this.control.Parent?.VCX) {
      this.props.onVCXChanged(this.control.VCX, this.element);
    }
  }

  render() {
    return (
      <div ref={(ref) => (this.element = ref)} style={StyleHelper(this.control, this.props.style)} className={css.rb_toolbar_base}>
        <div className={css.rb_toolbar_content}>
          <div className={css.rb_toolbar_buttons}>
            {this.control.Sections.map((value) => {
              return (
                <K2InnerSection
                  size={this.props.size}
                  controlUID={value.MetaData.ControlUID}
                  vrUID={this.control.getRealizerUID()}
                  key={value.MetaData.ControlUID}
                />
              );
            })}
          </div>
        </div>
        {this.props.showMenu && (
          <div className={css.rb_toolbar_menu}>
            <K2RibbonAction controlUID={this.control.Ribbon.OthersBtn.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
          </div>
        )}
        {
          <div className={css.rb_toolbar_hideBtn}>
            {(this.control.Parent as NclRibbon).ToolBars.length > 1 && (
              <K2RibbonAction controlUID={this.control.Ribbon.TabHideBtn.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} heightIcon={16} />
            )}
          </div>
        }
      </div>
    );
  }
}

const K2InnerToolBar = withVCXInCSS(_InnerToolBar);

interface K2AccessorProps extends WithVCXinCSSProps {
  handleClick: () => void;
  isActive: boolean;
}

class _Accessor extends React.PureComponent<K2AccessorProps, K2ComponentState<UpdateControl>> {
  private control: NclAccessor;
  private element: HTMLElement;

  constructor(props: K2AccessorProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclAccessor;
    }) as NclAccessor;
    this.state = { data: this.control.init(this), vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidMount(): void {
    if (this.element && this.props.onVCXChanged && this.control.VCX != this.control.Parent?.VCX) {
      this.props.onVCXChanged(this.control.VCX, this.element);
    }
  }

  componentDidUpdate(prevProps: Readonly<K2AccessorProps>, prevState: Readonly<K2ComponentState<UpdateControl>>, snapshot?: any): void {
    if (prevState.vcxVersion !== this.state.vcxVersion) {
      if (this.props.onVCXChanged && this.element) {
        this.props.onVCXChanged(this.control.VCX, this.element);
      }
    }
  }

  render(): JSX.Element {
    let clsName: string = css.rb_accestor_page;
    if (this.props.isActive) clsName += " " + css.rb_accestor_page_active;

    return (
      <div
        ref={(ref) => (this.element = ref)}
        style={StyleHelper(this.control, this.props.style)}
        data-k2-test-id={this.control.MetaData.Name}
        onClick={() => {
          this.props.handleClick();
        }}
        className={clsName + " " + css.rb_accestor_page_font}
      >
        <span className={css.rb_accestor_page_text}>{this.control.Ncl.Caption}</span>
      </div>
    );
  }
}

const K2Accessor = withVCXInCSS(_Accessor);

interface RibbonState extends K2ComponentState<UpdateRibbon> {
  flowVisible: boolean;
  width: number;
}

class _Ribbon extends React.PureComponent<WithVCXinCSSProps, RibbonState> {
  private control: NclRibbon;
  private element: HTMLDivElement;

  constructor(props: WithVCXinCSSProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclRibbon;
    }) as NclRibbon;
    this.state = { data: this.control.init(this) as UpdateRibbon, vcxVersion: -1, flowVisible: false, width: window.innerWidth };
    window.addEventListener("resize", this.setWidth);
  }

  updateState(state: UpdateRibbon) {
    this.setState((prevState: K2ComponentState<UpdateRibbon>) => {
      return { data: state as UpdateRibbon, flowVisible: state.HideToolBars && prevState.data.CurrentToolBarIndex != state.CurrentToolBarIndex ? true : false };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  setWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.setWidth);
    window.removeEventListener("click", this.handleWindowClick, { capture: true });
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidMount(): void {
    if (this.element) {
      window.addEventListener("click", this.handleWindowClick, { capture: true });
      resolveContextMenu(this.element, this.handleContextMenu);
      if (this.props.onVCXChanged && this.control.VCX != this.control.Parent?.VCX) {
        this.props.onVCXChanged(this.control.VCX, this.element);
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<WithVCXinCSSProps>, prevState: Readonly<RibbonState>, snapshot?: any): void {
    if (prevState.vcxVersion !== this.state.vcxVersion) {
      if (this.props.onVCXChanged && this.element) {
        this.props.onVCXChanged(this.control.VCX, this.element);
      }
    }
  }

  render() {
    const vr: ViewRealizer = ViewRealizerManager.getViewRealizer(this.control.getRealizerUID());
    let clsToolbarName = css.rb_toolbar;
    if (this.control.ToolBars.length > 1) {
      clsToolbarName += " " + css.rb_toolbar_multi;
    }
    let flowTolbarStyle: React.CSSProperties;
    if (this.state.flowVisible && this.element) {
      flowTolbarStyle = {
        position: "absolute",
        width: this.element.offsetWidth + "px",
        top: this.element.offsetTop + this.element.offsetHeight + "px",
        zIndex: vr.getDepth() + 1,
      };
    }
    return (
      <div
        ref={(ref) => {
          this.element = ref;
        }}
        style={StyleHelper(this.control, { ...this.props.style, minHeight: "0px" })}
        className={css.rb_base}
      >
        {this.control.ToolBars.length > 1 && (
          <div className={css.rb_header}>
            <div style={{ zIndex: this.state.flowVisible ? vr.getDepth() + 1 : "unset" }} className={css.rb_pages}>
              {this.control.ToolBars.map((value, index) => {
                return (
                  <K2Accessor
                    key={this.control.MetaData.ControlUID + "_accessor_" + index}
                    controlUID={value.Accessor.MetaData.ControlUID}
                    vrUID={value.Accessor.getRealizerUID()}
                    handleClick={() => {
                      this.handleClick(index);
                    }}
                    isActive={(!this.state.data.HideToolBars || this.state.flowVisible) && index == this.state.data.CurrentToolBarIndex}
                  />
                );
              })}
            </div>
            <div className={css.rb_menu}>
              <K2RibbonAction controlUID={this.control.OthersBtn.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
            </div>
          </div>
        )}
        {(!this.state.data.HideToolBars || this.state.flowVisible || this.state.width < Context.DeviceInfo.ResponsiveBreakpoints[0]) && (
          <div style={flowTolbarStyle} className={clsToolbarName} data-overlay={this.state.flowVisible ? "1" : "0"}>
            {this.control.ToolBars.map((value, index) => {
              if (this.state.data.CurrentToolBarIndex == index) {
                return (
                  <K2InnerToolBar
                    key={this.control.MetaData.ControlUID + "_tab_" + value.ToolBar.MetaData.ControlUID}
                    showMenu={this.control.ToolBars.length == 1}
                    controlUID={value.ToolBar.MetaData.ControlUID}
                    vrUID={this.control.getRealizerUID()}
                    size={this.control.Size}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
    );
  }

  private handleContextMenu = (e: MouseEvent) => {
    this.control.contextMenu();
    e.preventDefault();
  };

  private handleWindowClick = (e: MouseEvent) => {
    if (this.state.flowVisible) {
      if (this.element && this.element.contains(e.target as Node)) return;
      this.setState({ flowVisible: false });
    }
  };

  private handleClick(index: number) {
    if (index === this.state.data.CurrentToolBarIndex && this.state.data.HideToolBars) {
      this.setState({ flowVisible: !this.state.flowVisible });
    } else {
      this.control.changeCurrentToolBar(index);
    }
  }
}

export const K2Ribbon = withVCXInCSS(_Ribbon);
