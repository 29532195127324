import React, { useRef, useContext, useEffect, useMemo, useState } from "react";
import { Context } from "../../appcontext";
import { UpdateFormattableInput, UpdateWebContentEditor } from "../../common/communication.base";
import { NclWebContentEditor, TWebContentEditableMode } from "../../common/components.ncl";
import { AppContext } from "../../context";
import { useServerState } from "../hooks";
import { WithContextPlacementProps } from "../k2hoc";
import "./WebContentEditor.scss";
import { getAttributes } from "../../common/common";
import { K2BusyIndicator } from "../BusyIndicator/K2BusyIndicator";

const K2WebContentEditor = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclWebContentEditor, UpdateWebContentEditor, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclWebContentEditor
  );
  const iframe = useRef<HTMLIFrameElement>(null);
  const busyWrap = useRef<HTMLDivElement>(null);
  const firstLoad = useRef<boolean>(true);
  const [currentUrl, setCurrentUrl] = useState<string>(data.CurrentUrl);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    window.addEventListener("blur", activeElementChanged, true);

    return () => {
      window.removeEventListener("message", handleMessage);
      window.removeEventListener("blur", activeElementChanged, true);
    };
  }, []);

  useEffect(() => {
    updateData();
  }, [data.Json, data.AppendedImage]);

  useEffect(() => {
    if (data.NeedsReload) {
      setCurrentUrl(data.CurrentUrl);
      control.appendFunction({ Name: "ReloadDone", Args: [] }, true);
    }
  }, [data.NeedsReload]);

  function handleMessage(event: MessageEvent) {
    if (event?.data?.function === "NotifyFieldChanged") {
      control.appendFunction({ Name: "NotifyFieldChanged", Args: event?.data?.payload || [] }, true);
    }
    if (event?.data?.function === "AppendImage") {
      control.appendFunction({ Name: "AppendImage", Args: [] }, true);
    }
  }

  function activeElementChanged() {
    if (document.activeElement === iframe.current) {
      handleFocus();
    }
  }

  function handleIframeLoad() {
    if (busyWrap.current) busyWrap.current.style.display = "none";
    updateData();
  }

  function updateData() {
    if (iframe.current && iframe.current.contentWindow && data.Json) {
      iframe.current.contentWindow.postMessage({ function: "SetControlData", payload: [data.Json] }, "*");
      if (data.AppendedImage?.length > 0) {
        iframe.current.contentWindow.postMessage({ function: "ImageAppended", payload: [data.AppendedImage] }, "*");
      }
    }
  }

  function handleFocus() {
    control.setActiveControlRequested();
    control.setAsActiveControl();
    control.updateFocus(true);
  }

  if (firstLoad.current) firstLoad.current = false;
  return (
    <div className="wce" key={currentUrl} ref={element}>
      {currentUrl && (
        <div ref={busyWrap}>
          <K2BusyIndicator visibility={true}></K2BusyIndicator>
        </div>
      )}
      <iframe
        ref={iframe}
        onLoad={handleIframeLoad}
        onError={() => {
          if (busyWrap.current) busyWrap.current.style.display = "none";
        }}
        src={currentUrl}
        className="wv_object wv_iframe"
        sandbox="allow-scripts allow-popups allow-modals allow-forms allow-same-origin allow-presentation"
        allowFullScreen
      ></iframe>
    </div>
  );
};
export default K2WebContentEditor;

interface EditorProps {
  text: string;
  name: string;
  inEditMode: boolean;
  data?: UpdateFormattableInput;
  onChange: (name: string, value: string) => void;
  initRef?: (element: HTMLIFrameElement) => void;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: (e: FocusEvent) => void;
}

export const Editor = (props: EditorProps) => {
  const appContext = useContext(AppContext);
  const element = useRef<HTMLIFrameElement>(null);
  const timer = useRef<any>(-1);
  const subContent = useRef<string>(null);
  const focus = useRef<boolean>(null);

  //Pro WebContentEditor property vnitř
  useEffect(() => {
    handleFeedContent();
  }, [props.text]);

  const onChange = (content: string) => {
    if (!content.startsWith("<html>")) {
      content = "<html>" + content + "</html>";
    }

    subContent.current = content;
    props.onChange(props.name, content);
  };

  const handleFeedContent = () => {
    const htmlWrap = element.current?.contentWindow?.document.querySelector(".html_wrap");

    if (htmlWrap == null) return;

    const text = props.text.replace(/[\r\n]/g, "");
    // Pokud je rozdíl mezi odeslaným change a příchozím textem
    // Pokud není focus a je rozdíl mezi realným content a príchozím textem (např. změna jazyka)
    if (subContent.current !== text || (htmlWrap.innerHTML !== text && !focus.current)) {
      htmlWrap.innerHTML = text;
    }
  };

  const handleLoad = () => {
    element.current?.contentWindow?.document.addEventListener("keydown", (e) => {
      if (e.key.match(/^[a-z]$/i) || e.code.includes("Numpad") || e.code.includes("Arrow") || ["Enter", "Delete", "Backspace"].includes(e.key)) {
        return;
      }

      Context.getApplication().keyDown(e);
    });

    if (!props.inEditMode) return;

    const htmlWrap = element.current?.contentWindow?.document.querySelector(".html_wrap");

    if (htmlWrap == null) return;

    handleFeedContent();
    htmlWrap.addEventListener("input", handleInput);
    htmlWrap.addEventListener("focus", (e: FocusEvent) => {
      focus.current = true;
      props.onFocus(e);
    });
    htmlWrap.addEventListener("blur", (e: FocusEvent) => {
      focus.current = false;
      props.onBlur(e);
    });
  };

  const handleInput = (e: Event) => {
    const target = e.currentTarget as HTMLDivElement;

    if (target.tagName !== "DIV") return;

    if (timer.current > -1) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      if (target.getAttribute("k2editortype") === "code") {
        onChange((element.current?.contentWindow as any).unescapeHTML((target.childNodes[1] as HTMLDivElement).innerHTML));

        return;
      }

      onChange(target.innerHTML);
    }, 300);
  };

  const refCallback = (el: Element) => {
    if (el instanceof HTMLIFrameElement) {
      element.current = el;
      props.initRef?.(el);
    }
  };

  return (
    <iframe
      title="Editor"
      data-k2-test-id={props.name}
      className={`wce_iframe${props.inEditMode ? " edit" : ""}`}
      srcDoc={props.inEditMode ? undefined : props.text}
      src={props.inEditMode ? "./editable.html" : undefined}
      ref={refCallback}
      onLoad={handleLoad}
      style={{ pointerEvents: appContext?.pointerEvents }}
      {...getAttributes(props.data)}
    />
  );
};
